import React from "react";
import { createContext, useReducer } from "react";
import actionType from "constants/actionTypes";
import PathsAPI from "constants/PathsAPI";
import { toast } from "react-toastify";

export const AuthContext = createContext();

export const authReducer = (state, action) => {
	switch (action.type) {
		case actionType.LOGIN:
			return { ...state, user: action.payload };
		case actionType.LOGOUT:
			return { ...state, user: false };
		case actionType.VIEW_MENU:
			return { ...state, menu: action.payload };
		case actionType.SET_ORDER_DATE:
			localStorage.setItem("orderDate", action.payload);
			return { ...state, orderDate: action.payload };
		default:
			return state;
	}
};

export const AuthContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(authReducer, {
		user: null,
		menu: true,
		orderDate: new Date(localStorage.getItem("orderDate") || new Date()),
	});

	if (state.user === null) {
		fetch(PathsAPI.UserLogged)
			.then(async (resp) => {
				const userDetails = await resp.json();

				if (resp.ok && userDetails) {
					dispatch({
						type: actionType.LOGIN,
						payload: { supplier: userDetails.supplier, role: userDetails.role, fname: userDetails.fname, email: userDetails.email },
					});
				} else {
					dispatch({ type: actionType.LOGIN, payload: false });
				}
			})
			.catch((error) => {
				toast.error(error.message || "Wystąpił błąd podczas pobierania danych użytkownika");
				dispatch({ type: actionType.LOGOUT });
			});
	}

	return <AuthContext.Provider value={{ ...state, dispatch }}>{children}</AuthContext.Provider>;
};
