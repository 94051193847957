import React from "react";
import { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Layout from "components/Layout";

import Home from "pages/Home";
import Login from "pages/Login";
import Signup from "pages/Signup";
import Logout from "pages/Logout";
import NotFound from "pages/NotFound";

import UserRoutes from "routes/User";

import { useAuthContext } from "hooks/useAuthContext";
import Version from "pages/version";
import packageJson from "../package.json";
import { toast } from "react-toastify";
import PasswordForget from "pages/PasswordForget";
import PasswordReset from "pages/PasswordReset";
import SignupConfirmUser from "pages/SignupConfirmUser";
import ProductsPage from "pages/Dashboard/User/Shop/Online/ProductsPage";
import DescriptionPage from "pages/Dashboard/User/Shop/Online/DescriptionPage";
import OnlinePage from "pages/Dashboard/User/Shop/Online";
import PresentationPage from "pages/PresentationPage";
import PathsAPI from "constants/PathsAPI";
import MobileLayout from "pages/mobile/Layout";
import MobileLogin from "pages/mobile/Login";
import MobileHome from "pages/mobile/Home";
import MobileTutorial from "pages/mobile/Tutorial";
import MobileSignup from "pages/mobile/Signup";
import MobileUserRoutes from "routes/MobileUser";
import TutorialPage from "pages/Tutorial";
import UserDashboard from "pages/Dashboard/User";

import Helpdesk from "pages/Dashboard/User/Helpdesk";
import HelpdeskMobile from "pages/mobile/Dashboard/HelpDeskMobile";
import KioskProductsPage from "pages/KioskProductsPage";

import { Capacitor } from "@capacitor/core";
import { CapacitorUpdater } from "@capgo/capacitor-updater";

function App() {
	const { user } = useAuthContext();
	const [isMobile, setMobile] = useState(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));

	useEffect(() => {
		fetch(PathsAPI.Version).then(async (resp) => {
			const currentVersion = await resp.json();
			const platform = Capacitor.getPlatform();
			if (currentVersion === packageJson.version) {
				return;
			}

			if (["android", "ios"].includes(platform)) {
				const version = await CapacitorUpdater.download({
					version: currentVersion,
					url: `https://update.foodbox.store/${currentVersion}/dist.zip`,
				});
				await CapacitorUpdater.set(version);
			} else {
				const versionReloadTry = Number(localStorage.getItem("versionReloadTry"));

				if (versionReloadTry < 1) {
					localStorage.setItem("versionReloadTry", versionReloadTry + 1);
					return document.location.reload();
				} else if (versionReloadTry < 2) {
					localStorage.setItem("versionReloadTry", versionReloadTry + 1);
				} else {
					localStorage.removeItem("versionReloadTry");
				}

				toast.error(
					<>
						<b>UWAGA</b>
						<br />
						Wykryto niezgodność wersji aplikacji z serwerem!
						<br />
						Zalecane odświeżenie strony!
					</>,
					{ autoClose: false, closeButton: false, closeOnClick: false, draggable: false }
				);
			}
		});

		const resizeEvent = window.addEventListener("resize", (e) => {
			setMobile(e.target.innerWidth < 700 ? true : false);
		});
		setMobile(window.innerWidth < 700 ? true : false);
		return () => {
			window.removeEventListener("resize", resizeEvent);
		};
	}, []);

	const mobileRoutes = () => {
		switch (user) {
			case null:
				return <Route path="*" element={<></>} />;

			case false:
				return (
					<>
						<Route index element={<MobileHome />} />
						<Route path="/dashboard/*" element={<Navigate to="/" />} />
						<Route path="logout" element={<Navigate to="/" />}></Route>
						<Route path="login" element={<MobileLogin />} />
						<Route path="signup" element={<MobileSignup />} />
						<Route path="tutorial" element={<MobileTutorial />} />
						<Route path="signup/confirm/:token" element={<SignupConfirmUser />} />
						<Route path="password/forget" element={<PasswordForget />} />
						<Route path="password/reset/:token" element={<PasswordReset />} />
						<Route path="/dashboard/helpdesk" element={<HelpdeskMobile />} />
						<Route path="/kiosk" element={<KioskProductsPage />} />
					</>
				);

			default:
				return (
					<>
						{MobileUserRoutes()}
						<Route index element={<Navigate to="dashboard" />} />
						<Route path="login" element={<Navigate to="/" />} />
						<Route path="signup" element={<Navigate to="/" />} />
						<Route path="logout" element={<Logout />}></Route>
					</>
				);
		}
	};

	const desktopRoutes = () => {
		switch (user) {
			case null:
				return <Route path="*" element={<></>} />;

			case false:
				return (
					<>
						<Route index element={<Home />} />
						<Route element={<Layout />}>
							<Route element={<UserDashboard />}>
								<Route path="/dashboard/tutorial" element={<TutorialPage />} />
								<Route path="/dashboard/helpdesk" element={<Helpdesk />} />
							</Route>
						</Route>
						<Route path="/dashboard/*" element={<Navigate to="/" />} />
						<Route path="logout" element={<Navigate to="/" />}></Route>
						<Route path="login" element={<Login />} />
						<Route path="signup" element={<Signup />} />
						<Route path="signup/confirm/:token" element={<SignupConfirmUser />} />
						<Route path="password/forget" element={<PasswordForget />} />
						<Route path="password/reset/:token" element={<PasswordReset />} />
						<Route path="/presentation" element={<PresentationPage />}>
							<Route path="/presentation/shop" element={<OnlinePage />} />
							<Route path="/presentation/shop/products/:id" element={<ProductsPage />}>
								<Route path="desc" element={<DescriptionPage />} />
							</Route>
						</Route>
						<Route path="/kiosk" element={<KioskProductsPage />} />
					</>
				);

			default:
				return (
					<>
						{UserRoutes()}
						<Route index element={<Navigate to="dashboard" />} />
						<Route path="login" element={<Navigate to="/" />} />
						<Route path="signup" element={<Navigate to="/" />} />
						<Route path="logout" element={<Logout />}></Route>
					</>
				);
		}
	};

	return (
		<BrowserRouter>
			<Suspense fallback={"Ładowanie"}>
				<Routes>
					{isMobile ? (
						<Route path="/" element={<MobileLayout />}>
							{mobileRoutes()}

							<Route path="/version" element={<Version />} />
							<Route path="*" element={<NotFound />} />
						</Route>
					) : (
						<Route path="/" element={<Layout />}>
							{desktopRoutes()}
							<Route path="/version" element={<Version />} />
							<Route path="*" element={<NotFound />} />
						</Route>
					)}
				</Routes>
			</Suspense>
		</BrowserRouter>
	);
}

export default App;
